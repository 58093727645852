<template>
    <el-dialog class="noheader-dialog" width="500px" :show-close="false" :visible.sync="dialogVisible"
               :before-close="handleClose">
        <div class="content">
            <div class="row">
                <div class="label">考试名称</div>
                <div class="right">
                    <el-input
                            name="name"
                            placeholder="请输入考试名称"
                            v-model="name"
                    ></el-input>
                </div>
            </div>
            <div class="row">
                <div class="label">开始时间</div>
                <div class="right">
                    <el-date-picker
                            v-model="dateStart"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            format="yyyy-MM-dd HH:mm"
                            :clearable="false"
                    ></el-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="label">截止时间</div>
                <div class="right">
                    <el-date-picker
                            v-model="dateEnd"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            format="yyyy-MM-dd HH:mm"
                            :clearable="false"
                    ></el-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="label">公布答案</div>
                <div class="right">
                    <el-select class="select-list" v-model="toPublic">
                        <el-option label="结束后立即公布" :value="1"></el-option>
                        <el-option label="暂不公布" :value="2"></el-option>
                        <el-option label="自定义公布时间" :value="3"></el-option>
                    </el-select>
                    <el-date-picker
                            v-model="publicTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            format="yyyy-MM-dd HH:mm"
                            :clearable="false"
                            v-show="toPublic === 3"
                    ></el-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="label">删除操作</div>
                <div class="right btn" @click="examDel">删除考试</div>
            </div>
        </div>
        <div class="footer">
            <el-button class="btn save-btn" type="primary" @click="updateExamInfo">保存</el-button>
            <el-button class="btn" @click="handleClose">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'Dialog',
        // props: ['dialogVisible', 'dialogList', 'name', 'id'],
        props: ['dialogVisible', 'dialogList'],
        data() {
            return {
                id: '',
                name: '',
                dateStart: '',
                dateEnd: '',
                toPublic: 1,
                publicTime: '',
                index: ''
            }
        },
      created() {
        // console.log('created', this.dialogList, this.dialogVisibles)
      },
      watch: {
        dialogVisible(val, old) {
          // console.log('dialogVisible', val)
        }
        // console.log('created', this.dialogList, this.dialogVisibles)
      },
      methods: {
            updateExamInfo() {
                const dateStart = this.common.binarySystem(this.dateStart)
                const dateEnd = this.common.binarySystem(this.dateEnd)
                const dateStartTime = new Date(dateStart).getTime()
                const dateEndTime = new Date(dateEnd).getTime()
                if (dateStartTime >= dateEndTime) {
                    this.$alert('截止时间要大于开始时间', '提示', {
                        confirmButtonText: '确定'
                    })
                    return
                }
                const data = {}
                data.id = this.id
                data.name = this.name
                data.dateStart = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateStart))
                data.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateEnd))
                const {toPublic} = this
                data.toPublic = toPublic === 2 ? 2 : 1
                if (data.toPublic === 1) {
                    data.publicTime = toPublic === 1 ? this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateEnd)) : this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(this.publicTime))
                }
                this.$emit('updateExamInfo', data, this.index)
            },
            examDel() {
                const that = this
                this.$confirm('是否确认删除本次考试', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    that.$emit('examDel', that.id, that.index)
                })
            },
            getInfo(item, index) {
                this.id = item.id
                this.name = item.name
                this.dateStart = item.dateStart
                this.dateEnd = item.dateEnd
                // toPublic 在后端只存在 1 和 2，类型 3 需要通过 publicTime 来判断
                if (item.toPublic === 1 && item.dateEnd !== item.publicTime) {
                    this.toPublic = 3
                } else {
                    this.toPublic = item.toPublic
                }
                this.publicTime = item.publicTime
                this.index = index
            },
            handleClose() {
                this.$emit('handleClose')
            }
        }
    }
</script>

<style lang="scss">
    .noheader-dialog {
        .el-dialog__header {
            padding: 0;
        }
    }
</style>
<style scoped lang="scss">
    .content {
        padding-bottom: 20px;

        .row {
            @include flex(flex-start);
            height: 64px;
            border-bottom: 1px solid #E5E5E5;
            font-size: 17px;
            color: #333;

            .label {
                flex-shrink: 0;
                width: 100px;
                color: #999999;
                text-align: center;
            }

            .right {
                flex-grow: 1;
                @include flex(flex-start);

                .select-list {
                    width: 140px;
                }
            }

            .btn {
                color: #FF3C30;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .footer {
        @include flex;
        margin-top: 20px;

        .btn {
            width: 140px;
            height: 44px;
            font-size: 17px;
        }

        .save-btn {
            margin-right: 28px;
        }
    }
</style>
