<template>
  <article v-loading="loading">
    <RouterNav class="mb-10" :name="name" v-if="superAdmin === '2'"></RouterNav>
    <h1 class="title" v-else>线上模考</h1>
    <section>
      <div class="select-column">
        <el-select class="search-select" v-model="gradeTypeId" @change="refresh">
          <el-option v-for="(item, index) in gradeList" :key="index"
                     :label="superAdmin === '2'?item.gradeName:item.className"
                     :value="item.gradeId || item.classId"></el-option>
        </el-select>
        <el-select class="search-select" v-model="status" @change="refresh">
          <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="content">
        <template v-for="(item, index) in list">
          <div class="list-item" :key="item.id">
            <div class="list-item__left">
              <h4 class="list-item__left-name">{{ item.name }}
                <span class="list-item__left-status col-309af2" v-if="item.statusName==='未开始'">【未开始】</span>
                <span class="list-item__left-status col-1DC060" v-else-if="item.statusName==='进行中'">【进行中】</span>
                <span class="list-item__left-status col-999" v-else-if="item.statusName==='已结束'">【已结束】</span>
              </h4>
              <p class="list-item__left-desc">
                {{ superAdmin === '2' ? item.gradeName : item.className }}
                <span style="margin: 0 14px;">{{ item.num }}/{{ item.totalNum }}人</span>
                {{ formatValue(item.score) }}/{{ item.totalScore }}分
              </p>
              <p class="list-item__left-desc" v-if="item.statusName==='未开始'">开始时间：{{ item.dateStart | trimT }}</p>
              <p class="list-item__left-desc" v-else>结束时间：{{ item.dateEnd | trimT }}</p>
            </div>
            <div class="list-item__right">
              <el-button class="btn-setting" @click="revise(item, index)" v-if="superAdmin === '2'">考试设置</el-button>
              <el-button class="btn-check" :class="{'btn-disable':item.statusName==='未开始'}"
                         @click="examDetail(item)">查看报告
              </el-button>
            </div>

            <!--          <div class="list-content">-->
            <!--            <div class="name">{{ item.name }}</div>-->
            <!--            <div class="grade">{{ superAdmin === '2' ? item.gradeName : item.className }}</div>-->
            <!--            <div class="score">-->
            <!--              <div>{{ item.score }}/{{ item.totalScore }}分</div>-->
            <!--              <div>{{ item.num }}/{{ item.totalNum }}完成</div>-->
            <!--            </div>-->
            <!--            <div class="time-list">-->
            <!--              <div-->
            <!--                  :class="['status-name', item.statusName === '进行中' ? 'col-1DC060' : item.statusName === '已结束' ? 'col-999' : '']">-->
            <!--                {{ item.statusName }}-->
            <!--              </div>-->
            <!--              <div>-->
            <!--                <span>{{ item.dateStart | trimT }}</span>-->
            <!--                <span class="text">至</span>-->
            <!--                <span>{{ item.dateEnd | trimT }}</span>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="btn-list">-->
            <!--              <el-button @click.stop="revise(item, index)" v-if="superAdmin === '2'">修改</el-button>-->
            <!--              <el-button class="check-btn">查看</el-button>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
          <div class="list-item__line" :key="item.id + '1'"></div>
        </template>
        <div class="text-center mt-20" v-if="totalCount>20">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="totalCount">
          </el-pagination>
        </div>
      </div>
    </section>

    <Dialog
        ref="dialog"
        :dialogVisible="dialogVisible"
        @examDel="examDel"
        @updateExamInfo="updateExamInfo"
        @handleClose="handleClose"
    ></Dialog>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav.vue'
import Dialog from './components/Dialog.vue'

// copy element-ui 的 backtop 代码
const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => value < 0.5
    ? cubic(value * 2) / 2
    : 1 - cubic((1 - value) * 2) / 2;

export default {
  name: 'examList',
  components: {
    RouterNav,
    Dialog
  },
  data() {
    return {
      loading: false,
      name: '',
      schoolId: '',
      gradeList: [{gradeId: '', classId: '', gradeName: '全部年级', className: '全部班级'}],
      gradeTypeId: '',
      statusList: [{id: '', name: '全部状态'}, {id: 0, name: '未开始'}, {id: 1, name: '进行中'}, {id: 2, name: '已结束'}],
      status: '',
      list: [],
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      dialogVisible: false,
      superAdmin: 0
    }
  },
  created() {
    this.superAdmin = sessionStorage.getItem('superAdmin')
    // 只要是ai英语课堂(aiClient)客户端登录时，默认非超级教师
    const loginFrom = sessionStorage.getItem('loginFrom')
    if(loginFrom === 'ai-client'){
      this.superAdmin = 0
    }
    if (this.$route.query.classId){
      this.gradeTypeId = this.$route.query.classId
    }
    const {schoolId} = this.$route.query
    const {name} = this.$route.query
    this.name = name
    this.schoolId = schoolId
    this.getList()
    this.getGradeTypeById()
  },
  methods: {
    handleSizeChange(val) {
      this.pageIndex = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getList()
    },
    examDetail(item) {
      if (item.statusName === '未开始') {
        return
      }
      const {provinceId, section} = this.$route.query
      const unfinishNum = item.totalNum - item.num
      const dataParam = {
        section,
        provinceId,
        bagId: item.id,
        name: item.name,
        classId: item.classId || '',
        className: item.className || '',
        gradeName: item.gradeName,
        statusName: item.statusName,
        unfinishNum,
        flag: item.flag
      }
      if (this.superAdmin === '2') {
        dataParam.examId = item.id
      } else {
        dataParam.itemId = item.itemId
      }
      this.$router.push({
        name: 'examDetail',
        query: dataParam
      })
    },
    updateExamInfo(data, index) {
      this.loading = true
      this.dialogVisible = false
      this.$axios({
        method: 'post',
        url: this.$urls.updateExamInfo,
        headers: {
          'Content-Type': 'application/json'
        },
        data
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.list[index].name = data.name
          this.list[index].dateStart = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(data.dateStart))
          this.list[index].dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(data.dateEnd))
          this.list[index].toPublic = data.toPublic
          if (data.publicTime) {
            this.list[index].publicTime = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(data.publicTime))
          }
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        }
      }).catch(() => {
        this.loading = false
      })
    },
    examDel(id, index) {
      this.loading = true
      this.dialogVisible = false
      this.$axios({
        method: 'post',
        url: this.$urls.deleteExam,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          id
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.list.splice(index, 1)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    revise(item, index) {
      this.$refs.dialog.getInfo(item, index)
      this.dialogVisible = true
    },
    refresh() {
      this.pageIndex = 1
      this.getList()
    },
    getList() {
      console.log(233, 'getList')
      const {gradeTypeId} = this
      const {status} = this
      const dataParam = {}
      let url
      if (this.superAdmin === '2') {
        url = this.$urls.findExamListBySchoolId
        dataParam.schoolId = this.schoolId
        if (gradeTypeId !== '') dataParam.gradeTypeId = gradeTypeId
      } else {
        url = this.$urls.findExamListByTeacherId
        if (gradeTypeId !== '') dataParam.classId = gradeTypeId
      }
      if (status !== '') dataParam.status = status
      const {pageIndex} = this
      const {pageSize} = this
      dataParam.pageIndex = pageIndex
      dataParam.pageSize = pageSize
      this.loading = true
      this.$axios({
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParam
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {result} = response.info
          for (let i = 0, len = result.length; i < len; i += 1) {
            result[i].dateStart = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(result[i].dateStart))
            result[i].dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(result[i].dateEnd))
            if (result[i].publicTime) {
              result[i].publicTime = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(result[i].publicTime))
            }
          }
          console.log(270, result)
          this.list = result
          this.totalCount = response.info.totalCount

          this.scrollToTop()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    getGradeTypeById() {
      this.$axios({
        method: 'post',
        url: this.$urls.getGradeTypeById,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          schoolId: this.schoolId
        }
      }).then((response) => {
        if (response.state === '11') {
          const {info} = response
          const list = []
          for (let i = 0, len = info.length; i < len; i += 1) {
            if (info[i]) list.push(info[i])
          }
          this.gradeList = [...this.gradeList, ...list]
        }
      })
    },
    /**
     * ElementUI 的 backtop 代码
     */
    scrollToTop() {
      const el = document.querySelector('#app');
      const beginTime = Date.now();
      const beginValue = el.scrollTop;
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = 0;
        }
      };
      rAF(frameFunc);
    },
    // 格式化数值
    formatValue(val, precision = 1) {
      return (val || 0).toFixed(precision)
    }
  }
}
</script>

<style scoped lang="scss">
.mb-10 {
  margin-bottom: 10px !important;
}

.title {
  height: 84px;
  line-height: 84px;
  padding-left: 28px;
  font-size: 20px;
}

.select-column {
  @include flex(flex-start);
  background-color: #fff;
  height: 56px;
  padding-left: 28px;
  margin-bottom: 1px;

  .search-select {
    width: 140px;
    margin-right: 10px;
  }
}

.content {
  background-color: #fff;
  padding: 10px 28px;

  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 28px;
    border-radius: 8px;

    &:hover {
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .08);
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-name {
        color: #333333;
        font-size: 17px;
      }

      &-status {
        font-size: 15px;
        margin-left: 10px;
      }

      &-desc {
        color: #999999;
        font-size: 15px;
        margin-top: 6px;
      }
    }

    &__right {
      display: flex;
      align-items: center;

      .btn-setting {
        color: #309AF2;
        border: 1px solid #309AF2;
      }

      .btn-check {
        color: #FFFFFF;
        border: none;
        background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);

        &:hover {
          background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
        }
      }

      .btn-disable {
        background: #C0E0FB !important;
        cursor: not-allowed;
      }

      button {
        width: 100px;
        height: 40px;
        font-size: 15px;
      }
    }

    .list-content {
      @include flex(flex-start);
      height: 100px;
      border-bottom: 1px solid #F1F1F1;
      font-size: 17px;
      text-align: center;

      .name {
        flex-grow: 1;
        font-weight: bold;
        text-align: left;
      }

      .grade {
        flex-shrink: 0;
        width: 130px;
      }

      .score {
        flex-shrink: 0;
        width: 130px;
        font-size: 15px;
      }

      .time-list {
        flex-shrink: 0;
        width: 280px;
        font-size: 14px;

        .status-name {
          font-size: 15px;
          color: #309AF2;
          margin-bottom: 10px;
        }

        .text {
          display: inline-block;
          margin: 0 10px;
        }
      }

      .btn-list {
        flex-shrink: 0;
        @include flex(flex-end);
        width: 136px;

        .el-button {
          height: 30px;
          border-radius: 15px;
          font-size: 13px;
          color: #1DC060;
          border-color: #1DC060;
        }

        .check-btn {
          border-radius: 15px;
          margin-left: 14px;
          color: #309AF2;
          border-color: #309AF2;
        }
      }
    }
  }

  .list-item__line {
    height: 1px;
    background-color: #F1F1F1;
    margin: 0 28px;
  }

}
</style>
